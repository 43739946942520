h1, h2 {
	font-family: "Raleway", sans-serif;
}
body, html {
	margin: 0 auto;
	width: 95%;
	max-width: 800px;
	font-family: "Source Sans Pro", sans-serif;
}
label {
	display: block;
}
table {
	border-collapse: collapse;
	width: 100%;
}
td, th {
	padding: 8px;
}
table thead tr {
	background-color: #000;
	color: #fff;
	font-weight: bold;
	border: 3px solid #000;
	border-bottom: 3px solid #cccccc;
}
table tbody td {
	border: 1px solid #999999;
}
.attribution {
	font-size: 80%;
	font-style: italic;
}
